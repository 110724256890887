//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemModal          = lib.Item.Modal
export const ItemDiv            = lib.Item.Div
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class CpaService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = 'dtl itm'.split(/\W+/)
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = values?.id ? values.knt_name : 'new...'
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const CpaTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <span     *ngIf ='btns.tr.length && ctx.cpa_id' class='actions top floatr {{ctx.header && "header"}}'>
      <button *ngFor='let btn of btns.tr'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK("form", $event)'>{{btn.lab}}</button>
    </span>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details  </div>
    <div *ngIf='stabs.itm'  (click)='stabSel("itm")' class='{{stabCls("itm")}}'>items    </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <CpaDtl    *ngSwitchDefault     ></CpaDtl   >
    <CpaItmLst *ngSwitchCase='"itm"'></CpaItmLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
