//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module


export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const MixedComp          = lib.Mixed.Comp
export const MixedDiv           = lib.Mixed.Div
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class OlsService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = 'dtl chn ddc pay sku brn cat ftr rfr pmo sal'.split(/\W+/)
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = values?.id ? values.name : 'new...'
    }
    //--------------------------------------------------------------------------
    log_ack                     = (item:any) => this.emitP('log_ack'   , item)
    log_reload                  = (item:any) => this.emitP('log_reload', item)
    sku_add                     = (item:any) => this.emitP('sku_add'   , item)
    sku_remove                  = (item:any) => this.emitP('sku_remove', item)
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const OlsDiv             = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details               </div>
    <div *ngIf='stabs.chn'  (click)='stabSel("chn")' class='{{stabCls("chn")}}'>channels              </div>
    <div *ngIf='stabs.ddc'  (click)='stabSel("ddc")' class='{{stabCls("ddc")}}'>direct debit customers</div>
    <div *ngIf='stabs.pay'  (click)='stabSel("pay")' class='{{stabCls("pay")}}'>payment types         </div>
    <div *ngIf='stabs.sku'  (click)='stabSel("sku")' class='{{stabCls("sku")}}'>sku mapping           </div>
    <div *ngIf='stabs.brn'  (click)='stabSel("brn")' class='{{stabCls("brn")}}'>brand whitelist       </div>
    <div *ngIf='stabs.cat'  (click)='stabSel("cat")' class='{{stabCls("cat")}}'>category blacklist    </div>
    <div *ngIf='stabs.ftr'  (click)='stabSel("ftr")' class='{{stabCls("ftr")}}'>footer categories     </div>
    <div *ngIf='stabs.rfr'  (click)='stabSel("rfr")' class='{{stabCls("rfr")}}'>referrer codes        </div>
    <div *ngIf='stabs.pmo'  (click)='stabSel("pmo")' class='{{stabCls("pmo")}}'>promo codes           </div>
    <div *ngIf='stabs.sal'  (click)='stabSel("sal")' class='{{stabCls("sal")}}'>sales                 </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <OlsDtl    *ngSwitchDefault     ></OlsDtl   >
    <OlsChnLst *ngSwitchCase='"chn"'></OlsChnLst>
    <OlsDdcLst *ngSwitchCase='"ddc"'></OlsDdcLst>
    <OlsPayLst *ngSwitchCase='"pay"'></OlsPayLst>
    <OlsSkuLst *ngSwitchCase='"sku"'></OlsSkuLst>
    <OlsBrnLst *ngSwitchCase='"brn"'></OlsBrnLst>
    <OlsCatLst *ngSwitchCase='"cat"'></OlsCatLst>
    <OlsFtrLst *ngSwitchCase='"ftr"'></OlsFtrLst>
    <OlsRfrLst *ngSwitchCase='"rfr"'></OlsRfrLst>
    <OlsPmoLst *ngSwitchCase='"pmo"'></OlsPmoLst>
    <OlsSalLst *ngSwitchCase='"sal"'></OlsSalLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
