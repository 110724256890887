//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, regexTest
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , VrgTemplate           , TabbedComp
        , VrgService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'VrgLst', template:ListDiv})
export class VrgLst extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { date          : {lab:''                   , wide: 4, typ:'dates'}
//         , typ_name      : {lab:'type'               , wide: 5}
        , number        : {lab:'number'             , wide: 5}
        , ext_number    : {lab:'reference'          , wide: 6}
        , knt_name      : {lab:'account'            , wide:12}
//         , lnd_name      : {lab:'country'            , wide: 4}
        , chn_name      : {lab:'channel'            , wide: 8}
        , wrk_name      : {lab:'workflow'           , wide: 8}
        , rsr_name      : {lab:'location'           , wide: 8}
        , stt_name      : {lab:'status'             , wide: 8}
        , netto         : {lab:'netto'              , wide: 3, typ:'decimal', tot:true}
        , brutto        : {lab:'brutto'             , wide: 3, typ:'decimal', tot:true}
        , cur_typ       : {lab:'cur'                , wide: 2}
        , acc_netto     : {lab:'netto (€)'          , wide: 3, typ:'decimal', tot:true}
        , acc_brutto    : {lab:'brutto (€)'         , wide: 3, typ:'decimal', tot:true}
        , pay_typ       : {lab:'pay'                , wide: 2}
        , wsale_yn      : {lab:'w/s'                , wide: 1, typ:'flag'}
        , booked        : {lab:'bkd'                , wide: 1, typ:'flag'}
        , events_flag   : {lab:'evn'                , wide: 1, typ:'flag'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'load'
    //--------------------------------------------------------------------------
    onInitP() {
        let onNewFunction       = null
        if (this.ctx.canCreate) {
            try {
                this.ctx.newOpts.vorgang
                                = this.ctx.newOpts.vorgang.toLowerCase()
                this.ctx.newOpts.typ
                                = this.ctx.newOpts.vorgang
                this.ctx.newOpts.rsr_id
                                = this.ctx.rsr_id
                onNewFunction   = this.onNewP
            } catch(err) {
                delete this.ctx.newOpts
            }
        }
//TEST        this.dft                = this.ctx
        this.dft                =
            { ols_id            : this.ctx.ols_id
            , vorgang           : this.ctx.vorgang
            , channel           : this.ctx.channel
            , vendor            : this.ctx.vendor
            , workflow          : this.ctx.workflow
            , location          : this.ctx.location
            , rsr_id            : this.ctx.rsr_id
            , status            : this.ctx.status
            }

//         let listDefn            = Object.assign({}, this.listDefn)
        let listDefn            = { ...this.listDefn }
        if (this.ctx.referrer !== true) {
            // suppress referrer...
            delete listDefn.rfr_name
        }

        if (this.ctx.include_EUR !== true) {
            // suppress acc_netto/brutto...
            delete listDefn.acc_netto
            delete listDefn.acc_brutto
        }

        if (this.ctx.vorgang) {
            let typs            = is.array(this.ctx.vorgang)
                                    ?   this.ctx.vorgang
                                    : [ this.ctx.vorgang ]
            let allow_rsr       = typs.some((typ) =>
                                    regexTest(typ, 'dlv', 'ior', 'idl')
                                  )
            if (!allow_rsr) {
                // suppress location...
                delete listDefn.rsr_name
            }
        }

        this.defineList         (listDefn           )

        this.setHeader          ('=menu='           )
        this.buttonNew          (onNewFunction      )
        this.buttonSelAll       (this.onSelectAllP  )
        this.buttonInvSel       (this.onInvertSelP  )
        this.buttonPrint        (this.onPrintP      )
        if (regexTest(this.ctx.vorgang, 'inv') && this.ctx.canBookInvoices) {
            this.makeButton     (this.onAccCreateP  , 'book'    )
        }
        if (regexTest(this.ctx.vorgang, 'acc') && this.ctx.canBookInvoices) {
            this.makeButton     (this.onAccDownloadP, 'download')
        }
        if (regexTest(this.ctx.vorgang, 'cpa', 'ior')) {
            this.makeButton     (this.onGetDatafileP, 'download')
        }
        if (regexTest(this.ctx.vorgang, 'dlv')) {
           this.makeButton      (this.onWarehouseP  ,'change warehouse')
        }
        this.eventButtons       (this.ctx.events    )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        let since               = this.ctx.since    ? this.ctx.since
                                : this.ctx.status   ? 'month'
                                : this.ctx.vorgang  ? 'week'
                                                    : 'day'
        return Object.assign({ date0:this.getDateSince(since) }, this.ctx)
    }
    //--------------------------------------------------------------------------
    onNewCompName               = ''
    onNewP() {
        this.onNewCompName      = this.ctx.newPage
        return super.onNewP()
//         let ctx                 = this.onNewContext()
//         return this.callComponent(this.ctx.newPage, ctx)
//         .then((res:any) => this.onNewDoneP(res) )
    }
    onNewContext() {
        return this.clone(this.ctx, this.ctx.newOpts,
            { header            : 'new...'
            , id                : 0
            , canEdit           : true
            }
        )
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = ''
    onUpdateP(idx:number) {
        this.onUpdateCompName   = this.ctx.editPage
        return super.onUpdateP(idx)
//         let ctx                 = this.onUpdateContext(idx)
//         return this.callComponent(this.ctx.editPage, ctx)
//         .then((res:any) => this.onUpdateDoneP(idx, res) )
    }
    onUpdateContext(idx:number) {
        let item                = this.list[idx]
        let header              = (!item.knt_name)  ? item.number
                                                    : `${item.number}: ${item.knt_name}`
        let canEdit
        if ( regexTest(item.typ    , 'acc', 'inv')
          || regexTest(item.stt_typ, 'cmp', 'inv')
           ) {
            canEdit             = false
        } else {
            canEdit             = this.ctx.canEdit
        }
        return this.clone(this.ctx,
            { header            : header
            , id                : item.id
            , typ               : item.typ
            , canEdit           : canEdit
            }
        )
    }
    //--------------------------------------------------------------------------
    onEventP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            return this.busyServiceEmitP('event',
                { event         : key
                , vrg_ids       : ids
                })
        })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            this.messageText('print request submitted')
            return this.downloadP(
                { vrg_ids           : ids
                , TIMEOUT           : ids.length * 20
                },
                { name              : 'print'
                , task              : 'print'
                })
        })
    }
    //--------------------------------------------------------------------------
    onAccCreateP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => {
            this.messageText('booking selected invoices...')
            return this.serviceEmitP('acc_create', { vrg_ids:ids })
        })
        .then((ids:any) => this.accDownloadP(ids) )
    }
    //--------------------------------------------------------------------------
    onAccDownloadP(key:string, values:any) {
        return this.getSelectedIdsP()
        .then((ids:any) => this.accDownloadP(ids) )
    }
    //--------------------------------------------------------------------------
    accDownloadP(ids:any) {
        this.messageText('accounting download request submitted')
        return this.downloadP({ acc_ids:ids },
            { name              : 'accounting download'
            , task              : 'acc_download'
            })
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    onGetDatafileP(key:string, values:any) {
        return this.getSelectedItemsP()
        .then((items:any) =>
            this.downloadP({ filenames:items.map((item:any) => item.number + '.xlsx') },
                { name          : 'details'
                , task          : 'get_datafile'
                })
        )
    }
    //--------------------------------------------------------------------------
    async onWarehouseP(key:string, values:any) {
        let ids                 = await this.getSelectedIdsP()
        let res                 = await this.callComponent('ParamsModal',
            { header            : 'select new warehouse...'
            , itemDefn          :
                { rsr_id        : {lab:'warehouse', cod:'rsr'} }
            , values            : { rsr_id:0 }
            }
        )
        if (!res)               { return }
        await this.busyServiceEmitP('event',
            { event             : 'rsr'
            , vrg_ids           : ids
            , data              :
                { rsr_id_old    : 0
                , rsr_id_new    : res.rsr_id
                }
            }
        )
        this.messageDone('warehouse change scheduled')
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
@Component({selector:'Vrg', template:VrgTemplate})
export class Vrg extends TabbedComp {
    //--------------------------------------------------------------------------
    constructor( public service:VrgService ) { super() }
    //--------------------------------------------------------------------------
    onInitP() {
this.log('CTX:', this.ctx)
        this.service.initTabs(this.ctx.id, 'dtl')
        this.ctx.vrg_id         =
        this.dft.vrg_id         = this.ctx.id
        this.dft.vrg_typ        = this.ctx.typ
        this.dft.ols_id         = this.ctx.ols_id
        this.dft.newOpts        = this.ctx.newOpts

        // this is required otherwise a "save" tries to use the workflow...
        delete this.dft.workflow

this.log('DFT:', this.dft)
        this.cache              = {}
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onPrintP(key:string, values:any) {
        if (!this.ctx.vrg_id) {
            return REJECT('no document selected')
        }
        this.messageText('print request submitted')
        return this.downloadP(
            { vrg_ids           : [ this.ctx.vrg_id ]
            },
            { name              : 'print'
            , task              : 'print'
            })
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
