//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ListDiv               , ListComp
        , OlsService                                } from './service'
//------------------------------------------------------------------------------
@Component({selector:'OlsLog', template:ListDiv})
export class OlsLog extends ListComp {
    //--------------------------------------------------------------------------
    constructor( public service:OlsService ) { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    listDefn            :any    =
        { insert_date   : {lab:''                   , wide: 3, typ:'dates'}
        , order_num     : {lab:'shop order'         , wide: 3}
        , txt           : {lab:'text'               , wide:20, typ:'textarea'}
        , ack           : {lab:'ack'                , wide: 2, typ:'flag'}
        , reload        : {lab:''                   , wide: 2, typ:'bare', cls:'center'}
        }
    //--------------------------------------------------------------------------
    onLoad_task                 = 'log_load'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.onCriteria         (this.onCriteriaP   )
        this.listButton         ('ack'   , 'ack'    , this.whenAck   , this.onAckP   , 'center')
        this.listButton         ('reload', 'reload' , this.whenReload, this.onReloadP, 'center')

        this.dft.ols_id         = this.ctx.ols_id
        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    whenAck(item:any) {
        return (!item.ack)
    }
    onAckP(idx:number, key:string) {
        this.messageFetch()
        return this.service.log_ack(this.list[idx])
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    whenReload(item:any) {
        return true // (item.GROUP == 'single' || item.GROUP == 'group1st')
    }
    onReloadP(idx:number, key:string) {
        this.messageFetch()
        return this.service.log_reload(this.list[idx])
        .then(() => this.loadAndFocusP() )
    }
    //--------------------------------------------------------------------------
    defaultCriteria() {
        return this.glob.userIsDeveloper
            ? { date0:this.getDateSince('week') }
            : { date0:this.getDateSince('week'), ack:'n' }
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
