//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { ItemDiv, ItemComp, ChnService             } from './service'
//------------------------------------------------------------------------------
@Component({selector:'ChnDtl', template:ItemDiv})
export class ChnDtl extends ItemComp {
    //--------------------------------------------------------------------------
    constructor( public service:ChnService ) { super() }
    comp_type                   = 'maintab'
    //--------------------------------------------------------------------------
    itemDefn            :any    =
        { HIDE          : ['id','update_date']
        , name          : {lab:'channel'            , wide: 6               , copyCheck:true}
        , grouping      : {opt:'grouping'           , wide: 5}
        , sort_no       : {opt:'sort'               , wide: 1, typ:'integer'}

        , src_id        : {lab:'source'             , wide: 6, cod:'src'    , copyCheck:true}
        , vnd_id        : {lab:'default owner'      , wide: 6, cod:'vnd'    , copyCheck:true}

        , wrk_id_dlv    : {lab:'delivery workflow'  , wide: 6, cod:'wrk_dlv'}
        , wrk_id_inv    : {lab:'invoice workflow'   , wide: 6, cod:'wrk_inv'}

        , wrk_id_vdl    : {opt:'virtual delivery workflow'
                                                    , wide: 6, cod:'wrk_dlv'}
        , wrk_id_vin    : {opt:'virtual invoice workflow'
                                                    , wide: 6, cod:'wrk_inv'}

        , wrk_id_rtn    : {lab:'returns workflow'   , wide: 6, cod:'wrk_dlv'}
        , wrk_id_crn    : {lab:'credit-note workflow'
                                                    , wide: 6, cod:'wrk_inv'}

        , wrk_id_dld    : {opt:'discontinued delivery workflow'
                                                    , wide: 6, cod:'wrk_dlv'}
        , wrk_id_cnd    : {opt:'discontinued credit-note workflow'
                                                    , wide: 6, cod:'wrk_inv'}

        , wrk_id_edl    : {opt:'external delivery workflow'
                                                    , wide: 6, cod:'wrk_dlv'}
        , wrk_id_ein    : {opt:'external invoice workflow'
                                                    , wide: 6, cod:'wrk_inv'}
        }
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineItem         (this.itemDefn      )
        if (this.ctx.chn_id) {
            this.buttonCopy     (this.onCopyP       )
            this.buttonDelete   (this.onDeleteP     )
        }
        this.buttonOk           (this.onSubmitP     )
        this.formSubmit         (this.onSubmitP     )

        return this.readP({ id:this.dft.chn_id })
        .then(() => this.setFocusP() )
    }
    //--------------------------------------------------------------------------
    receivedItem(item:any) {
        Object.assign(this.item, item)
        this.ctx.id             =
        this.ctx.chn_id         =
        this.dft.chn_id         = this.item.id
        this.setCache(this.item)
        this.service.setHeader(this.item)
        if (this.item.id) {
            this.service.showValidTabs()
            this.showButton('copy'  )
            this.showButton('delete')
        } else {
            this.hideButton('copy'  )
            this.hideButton('delete')
        }
    }
    //--------------------------------------------------------------------------
    onCopyP() {
        return this.setItemValuesP({ id:0 })
        .then(() => {
            this.ctx.id         =
            this.ctx.chn_id     =
            this.dft.chn_id     = this.item.id
            this.setCache(this.item)
            this.service.setHeader(this.item)
            this.service.initTabs(this.item.id, 'dtl')
            this.hideButton('copy'  )
            this.hideButton('delete')
            return this.setFocusP()
        })
    }
    //--------------------------------------------------------------------------
    onDeleteDoneP(res:any) {
        return this.onBackP()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
