//------------------------------------------------------------------------------
declare const RESOLVE, REJECT, is, jQuery, setTimeoutZero, delayTimer, getStack
//------------------------------------------------------------------------------
import  { Base                                      } from './base'
import  { ListDiv                                   } from './templates/divs'
import  { Type, makeList                            } from './fields'
//------------------------------------------------------------------------------
export class ListComp extends Base.Comp {
    //--------------------------------------------------------------------------
    constructor() { super() }
    //--------------------------------------------------------------------------
    buttonRow                   = 't'
    //--------------------------------------------------------------------------
    implementsList      :boolean= true
    //--------------------------------------------------------------------------
    listChunkSize               = this.glob.config.listChunkSize || 100
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.buttonNew          (this.onNewP        )
        this.onCriteria         (this.onCriteriaP   )
        this.listClick          (this.onSelectItmP  )
        this.listDclick         (this.onUpdateP     )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    defineList(defn:any={}, labelsOnly:any=false) {
        if (this.lDef.KEYS && this.lDef.KEYS.length) {
            this.log('defineList called multiple times')
            return
        }
        defn                    = this.clone(defn)
        let lDef                = this.lDef
        if (this.showDev) {
            lDef.onDump         = this.onDump.bind(this)
            if (defn.id === undefined) {
                defn.id         = {lab:'id', wide:1, typ:'integer', cls:'left-border'}
            }
        }
        let KEYS                = Object.keys(defn).filter((key) => !/^[A-Z]+$/.test(key))
        lDef.HIDE               = makeList(defn.HIDE)
        lDef.KEYS               = [ ...lDef.HIDE, ...KEYS ]
        lDef.TOTKEYS            = []
        lDef.TOTS               = {}
        lDef.COLS               = {}
//         delete defn.HIDE
        if (KEYS.length < 1)    { return }
        let wTotal              = 0
        // calculate total of all widths
        // widths are relative across the page so we need the total to be able
        // to calculate the percentages and apply 'w<percent>' css classes
        KEYS.forEach((key, idx) => {
//             if (/^_/.test(key)) { return }
            lDef.COLS[key]      = idx
            let fld             = defn[key]
            fld.wide            = Number(fld.wide) || 1
            wTotal             += fld.wide
        })
        // calculate ratio so that (width * ratio -> percentage)
        // - allow a couple of percentage points for borders and padding etc.
        let wRatio              = wTotal > 0 ? 98 / wTotal : 0
        // run through fields again to apply all the good bits
        KEYS.forEach((key:string) => {
            let fld             =
            lDef[key]           = defn[key]
            fld.name            = key
            fld.typ             = (fld.cod) ? 'cod' : (fld.typ || 'text')
            // allow for list definitions being "copied" from item definitions (a bit)...
            fld.lab             = fld.lab || fld.opt || fld.rdo || ''
            fld.off             = Boolean(fld.off)
            if (fld.tot) {
                lDef.TOTKEYS.push(key)
            }
            let typ             = Type[fld.typ] || Type['text']
            fld.format          = typ.format
            // header class...
            let cls             = []
            // percentage width from relative width
            cls.push('w' + Math.round(wRatio * fld.wide))
            if (typ.cls)        { cls.push(typ.cls) }
            fld.divCls          = cls.join(' ')
            // item class...
            cls                 = []
            if (fld.isKey)      { cls.push('isKey') }
            if (typ.cls)        { cls.push(typ.cls) }
            if (fld.cls)        { cls.push(fld.cls) }
            fld.cls             = cls.join(' ')
            fld.cellBTNs        = []
        })
        this.labelsOnly         = labelsOnly || false
    }
    //--------------------------------------------------------------------------
    listClick   (func)          { this.lDef.rowCLICK        = func.bind(this)   }
    listDclick  (func)          { this.lDef.rowDCLICK       = func.bind(this)   }
    cellClick(func, key) {
        let cell                = this.lDef[key]
        if (!cell) {
            this.log('invalid cellClick cell:', key)
            return
        }
        if (!is.function(func)) {
            this.log('invalid cellClick function on:', key)
            return
        }
        cell.cellCLICK          = func.bind(this)
    }
    cellDclick(func, key) {
        let cell                = this.lDef[key]
        if (!cell) {
            this.log('invalid cellDclick cell:', key)
            return
        }
        if (!is.function(func)) {
            this.log('invalid cellDclick function on:', key)
            return
        }
        cell.cellDCLICK         = func.bind(this)
    }
    cellsClick  (func, keys)    { keys.map((key) => this.cellClick (func, key)) }
    cellsDclick (func, keys)    { keys.map((key) => this.cellDclick(func, key)) }
    //--------------------------------------------------------------------------
    listButtons(buttons) {
        for (let key in buttons) {
            let defn            = buttons[key]
            this.listButton(key, defn.lab, defn.when, defn.func, defn.cls)
        }
    }
    //--------------------------------------------------------------------------
    listButton(key:string, lab:string, when:any, func:any, cls='') {
        if (!this.lDef[key]) {
            let err             = `listButton - field not found for key: '${key}'`
            this.messageError(err)
            this.log(err)
            return
        }

        let onFunc              = is.string(func) ? this[func] : func
        if (!is.function(onFunc)) {
            let err             = `listButton[${key}]: invalid function: ${func}`
            this.messageError(err)
            this.log(err)
            return
        }

        let cellBTN     :any    =
            { lab               : lab
            , cls               : cls               ? cls             : 'floatl'
            , when              : is.function(when) ? when.bind(this) : (item:any) => true
            , off               : false
            , hide              : false
            }

        Object.defineProperty(cellBTN, 'isOff', { get:() => cellBTN.off })
        cellBTN.click           = (idx:number) => {
            if (cellBTN.off || cellBTN.hide)
                                { return false }
            return onFunc.call(this, idx, key)
            .catch((err) => this.messageError(err) )
//             return false
        }
        this.lDef[key].cellBTNs.push(cellBTN)
    }
    //--------------------------------------------------------------------------
// this could be added automatically, but I decided to make if explicit in the
// interests of being opaque
    onCriteria(func:any) {
        this.lDef.onCRITERIA    = (criteria:any) => {
this.log('[onCriteria]', criteria)
            return func.call(this, criteria)
            .catch((err) => this.messageError(err) )
        }
    }
    //--------------------------------------------------------------------------
    loadP(criteria:any=null) {
this.log('[loadP]...')
        this.cancelFormatting_  = false
        if (criteria === null) {
            criteria            = this.criteria
        } else {
            this.criteria       = criteria
        }
        this.storeCriteria(criteria)
        this.messageFetch()
        return (this.onLoad_task == 'EMPTY'
            ? RESOLVE([])
            : this.serviceEmitP(this.onLoad_task, criteria)
            )
        .then((list:any) => {
            list                = this.loadResult(list) || []
            return this.setListP(list)
        })
        .then(() => this.loadDoneP() )
    }
    loadWithP(list:any=[]) {
        this.cancelFormatting_  = false
        this.messageFetch()
        return this.setListP(list)
        .then(() => this.loadDoneP() )
        .then(() => this.setFocusP() )
    }
    loadResult(list:any) {
        return list
    }
    loadDoneP() {
this.log('[loadDoneP]')
        this.cleanupTable()
        return RESOLVE()
    }
    loadAndFocusP(criteria:any=null) {
        if (this.dontLoadAll && this.emptyCriteria) {
            return this.setFocusP(this.messageNoLoad())
        } else {
            return this.setFocusP()
            .then(() => this.loadP(criteria) )
            .then(() => RESOLVE(this.messageClear()) )
        }
    }
    //--------------------------------------------------------------------------
    cleanupTable() {
//         if (this.list.length && jQuery('tr').length < this.list.length) {
//             setTimeout(() => this.cleanupTable(), 50)
//         } else {
// //             jQuery('form.ng-untouched.ng-pristine.ng-valid, tr, td')
// //                 .off('keydown').off('ngSubmit').off('reset').off('submit')
//         }
    }
    //--------------------------------------------------------------------------
    setListP(list:any) {
        if (this.cancelFormatting_) {
            this.onCancelFormatting()
            return REJECT()
        }
        this.messageUnlock('formatting...')
        list                    = this.preprocessList(list || [])
        this.groupList(list)
        this.list               = []
//         // do this since "this.list = []" seems to upset angular...
//         while (this.list.length) {
//             this.list.pop()
//         }
// this.log('[setListP] this.list cleared...')
        let TOTKEYS             = this.lDef.TOTKEYS
        let TOTS                = this.lDef.TOTS
        TOTKEYS.forEach((key) => {
            TOTS[key]           = 0
        })
// this.log('[setListP] TOTKEYS cleared...')
        let length              = list.length
        if (length < 1) {
            this.messageClear()
            return RESOLVE()
        }
        let chunks              = Math.ceil(length / this.listChunkSize)

        let loadChunk           = (resolve, reject, chunk) => {
// this.log('[setListP] loadChunk:', chunk)
            if (this.cancelFormatting_) {
// this.log('[setListP] cancelled...')
                this.onCancelFormatting()
                return reject(null)
            }
            let start           = chunk * this.listChunkSize
            let progress        = Math.round(start * 100 / length)
// this.log('[setListP] progress =', progress)
            this.messageText(`formatting: ${progress}%`)
            list.slice(start, start + this.listChunkSize).forEach(loadItem)
            if (chunk < chunks) {
                setTimeoutZero(loadChunk, resolve, reject, chunk + 1)
            } else {
                resolve(null)
            }
        }

        let loadItem            = (item) => {
            item.HIDDEN         = false
            item.SELECT         = false //this.forceSelect(item)
            this.setExtraValues(item)
            TOTKEYS.forEach((key:string) => {
                TOTS[key]      += item[key] || 0
            })
            this.list.push(item)
            this.setItemColour(item, this.list.length - 1)
        }

        return this.refocusP()
        .then(() => {
            this.cdStart()
            return new Promise((resolve, reject) =>
                setTimeoutZero(loadChunk, resolve, reject, 0)
            )
        })
        .catch((err) => {
            this.log('[setListP] chunking error:', err)
            return RESOLVE()
        })
        .then(() => {
this.log('[setListP] finished...')
            this.messageClear()
            return delayTimer(0)
        })
        .finally(() => this.cdStop() )
    }
    //--------------------------------------------------------------------------
    preformat_list(list) {
        // call this when the list is only updated via a reload...
        let lDef                = this.lDef
        list.forEach((item) => {
            let PREFORM         = item.PREFORM = {}
            lDef.KEYS.forEach((key) => {
                PREFORM[key]    = lDef[key].format(item[key])
            })
        })
        return list
    }
    //--------------------------------------------------------------------------
    setExtraValues(values:any) {}
    //--------------------------------------------------------------------------
    onCancelFormatting() {
        this.cancelFormatting_  = false
        this.messageError('list formatting cancelled')
    }
    preprocessList(list:any)  {
        return list
    }
    groupList(list:any) {
        if (!this.groupingKey) {
            return
        }
        let key                 = this.groupingKey
        let item, value, prev, next, GROUP
        list.forEach((item, idx) => {
            value               = item[key]
            next                = (list[idx + 1] || {})[key]
            if (value != prev) {
                if (value != next)
                                { GROUP = 'single'   }
                else            { GROUP = 'group1st' }
            } else {
                if (value != next)
                                { GROUP = 'groupLast'}
                else            { GROUP = 'group'    }
            }
            item.GROUP          = GROUP
            prev                = value
        })
    }
    //--------------------------------------------------------------------------
    colours                     =
        { none                  : 'var(--transparent)'
        , red                   : 'var(--red)'
        , orange                : 'var(--orange)'
        , yellow                : 'var(--yellow)'
        , green                 : 'var(--green)'
        , blue                  : 'var(--blue)'
        }
    setItemColour(item:any, idx:number) {}
    //--------------------------------------------------------------------------
    set_cell_bg(row, key, colour) {
        let cell                = jQuery('.list.container table tbody tr'
                                    ).eq(row
                                    ).find('td'
                                    ).eq(this.lDef.COLS[key]
                                    )
        if (cell.length > 0) {
            cell.css('background-color', this.colours[colour])
        } else {
            setTimeout(() => this.set_cell_bg(row, key, colour), 50)
        }
    }
    //------------------------------------------------------------------------------
    scrollIntoView(row) {
// this.log('scrollIntoView:', row)
        try      { jQuery('.list.container tr').eq(row).get(0).scrollIntoView() }
        catch(e) {}
    }
    //------------------------------------------------------------------------------
    // to enable this add to component:
    //      import { ChangeDetectorRef } from '@angular/core'
    // and:
    //      constructor( private cdr:ChangeDetectorRef, ...)
    cdInterval                  = null
    cdStart() {
        let cdr                 = this['cdr']
        if (!cdr)               { return }
        if (!this.cdInterval) {
            this.log('[ChangeDetector] already started.')
            return
        }
        this.cdInterval         = clearInterval(this.cdInterval)
        cdr.reattach()
        this.log('[ChangeDetector] started.')
    }
    cdStop() {
        let cdr                 = this['cdr']
        if (!cdr)               { return }
        if (this.cdInterval) {
            this.log('[ChangeDetector] already stopped.')
            return
        }
        let row_count           = jQuery('.list.container table tbody tr').length
        if (row_count < this.list.length) {
            this.log('[ChangeDetector] waiting for load to finish (%s of %s)...', row_count, this.list.length)
            setTimeout(() => this.cdStop(), 1000)
            return
        }
        cdr.detach()
        this.cdInterval         = setInterval(() => this.cdDetect(), 5000)
        this.log('[ChangeDetector] stopped.')
    }
    cdDetect() {
        let cdr                 = this['cdr']
        if (!cdr)               { return }
//         this.log('[ChangeDetector] detecting...')
        cdr.detectChanges()
    }
    //------------------------------------------------------------------------------
    onCriteriaP(criteria:any) {
this.log('[onCriteria]...')
        return this.loadP(criteria)
        .then(() => this.refocusP() )
    }
    //--------------------------------------------------------------------------
    onSelectItmP(idx:number) {
        let item                = this.list[idx]
        item.HIDDEN             = false
        item.SELECT             = !item.SELECT
        this.messageClear()
        return this.selectionChangedP()
    }
    //--------------------------------------------------------------------------
    onNewCompName               = ''
    onNewP() {
        let ctx                 = this.onNewContext()
        return this.callComponent(this.onNewCompName, ctx)
        .then((res:any) => this.onNewDoneP(res) )
    }
    onNewContext() {
        return { }
    }
    onNewDoneP(res:any) {
        if (!res)               { return RESOLVE() }
        return this.loadAndFocusP(null)
    }
    //--------------------------------------------------------------------------
    onUpdateCompName            = ''
    onUpdateP(idx:number) {
        let ctx                 = this.onUpdateContext(idx)
        return this.callComponent(this.onUpdateCompName, ctx)
        .then((res:any) => this.onUpdateDoneP(idx, res) )
    }
    onUpdateContext(idx:number) {
        return this.clone(this.ctx, { id: this.list[idx].id })
    }
    onUpdateDoneP(idx:number, res:any) {
this.log('[onUpdateDoneP] idx:', idx)
        return res ? this.loadAndFocusP(null) : RESOLVE()
    }
    //--------------------------------------------------------------------------
    onUpdateListP(idx:number) {
        let selectListItem      = (args:any) => {
// this.log('selectListItem:', args)
            let idx             = args.idx
            let list            = this.list
            if (args.prev > -1) {
                list[args.prev].HIDDEN
                                = false
                list[args.prev].SELECT
                                = false
            }
            list[idx].HIDDEN    = false
            list[idx].SELECT    = true
            this.scrollView($container, $container.find('table > tbody > tr').eq(idx))
        }
        let updateListItem      = (args:any) => {
// this.log('updateListItem:', args)
            return this.applyValuesToListP(args.idx, args)
            .catch((err:any) => {
                this.log('applyValuesToListP:', err)
                return RESOLVE()
            })
        }
        let subs                = { selectListItem  : selectListItem
                                  , updateListItem  : updateListItem
                                  }
        for (let key in subs) {
            subs[key]           = this.subscribe(key, subs[key])
        }
        let unsub               = () => {
            for (let key in subs) {
                this.unsubscribe(key, subs[key])
            }
        }
        let $container          = jQuery('.list.container')
        this.list.forEach((item:any) => {
            item.HIDDEN         = false
            item.SELECT         = false
        })
        this.ctx.idx            = idx
        this.cache[this.name]   = this.list
        return this.callComponent('', this.ctx)
        .then((res:any) => res ? updateListItem(res) : RESOLVE() )
        .finally(() => unsub())
    }
    //--------------------------------------------------------------------------
    applyValuesToListP(idx:number, values:any) {
// this.log('applyValuesToListP', [idx, values])
        let item                = this.list[idx]
        if (!item) {
            this.log('applyValuesToListP - invalid index:', [idx, values])
            return RESOLVE()
        }
        values                  = Object.assign({}, item, values)
        values.DIRTY            = true
        delete values.HIDDEN
        delete values.SELECT
// this.log('PRE  applyValuesToList_extra', { ...values })
        this.applyValuesToList_extra(idx, values)
// this.log('POST applyValuesToList_extra', { ...values })
        return this.applyValuesP(item, values)
        .then(() => this.applyValuesToListDoneP(idx, item) )
    }

    applyValuesToList_extra(idx:number, values:any) { }

    applyValuesToListDoneP(idx:number, item:any) {
        this.setItemColour(item, idx)
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
    onSelectNoneP(key:string) {
        this.list.forEach((item:any) => {
            item.HIDDEN         = false
            item.SELECT         = false
        })
        this.messageClear()
        return this.selectionChangedP()
    }
    onSelectAllP(key:string) {
        this.list.forEach((item:any) => {
            item.HIDDEN         = false
            item.SELECT         = true
        })
        this.messageClear()
        return this.selectionChangedP()
    }
    //--------------------------------------------------------------------------
    onInvertSelP(key:string) {
        this.list.forEach((item:any) => {
            item.HIDDEN         = false
            item.SELECT         = !item.SELECT
        })
        this.messageClear()
        return this.selectionChangedP()
    }
    //--------------------------------------------------------------------------
    getSelectedItemsP(opts:any={}) {
// this.log('getSelectedItemsP OPTS:', opts)
        let list                = this.list
        if (list.length < 1) {
            return REJECT('no items in list')
        }
        if (list.length == 1) {
//             list[0].HIDDEN      = false
            list[0].SELECT      = true
//             return confirmation(list)
        }
        let items               = list.filter((item:any, idx) => {
            item.HIDDEN         = false
            item.IDX            = idx
            return item.SELECT
        })
        if (items.length < 1) {
            if (opts.onlyOne || !opts.allowNone)
                                { return REJECT('nothing selected') }
        } else if (items.length > 1) {
            if (opts.onlyOne)   { return REJECT('more than one item selected') }
        }
        if (!opts.confirm)      { return RESOLVE(items) }

        return this.onActionConfirmP(opts.confirm, items.length)
        .then(() => RESOLVE(items) )
    }
    //--------------------------------------------------------------------------
    getSelectedIdUpdsP(opts:any={}) {
        return this.getSelectedItemsP(opts)
        .then((items:any) => RESOLVE(this.extractIdUpds(items)) )
    }
    //--------------------------------------------------------------------------
    getSelectedIdsP(opts:any={}) {
        return this.getSelectedItemsP(opts)
        .then((items:any) => RESOLVE(this.extractIds  (items)) )
    }
    //--------------------------------------------------------------------------
    extractIds(items:any) {
        return items.map((item:any) => item.id)
    }
    //--------------------------------------------------------------------------
    extractIdUpds(items:any) {
        return items.map((item:any) => ({ id:item.id, update_date:item.update_date }))
    }
    //--------------------------------------------------------------------------
    extractKeys(items:any) {
        return items.map((item:any) => item.key)
    }
    //--------------------------------------------------------------------------
    getSelectedItemP() {
        let list                = this.list
        if (list.length < 1) {
            return REJECT('no items in list')
        }
        if (list.length == 1) {
            list[0].HIDDEN      = false
            list[0].SELECT      = true
            return RESOLVE(list[0])
        }
        let items               = list.filter((item:any) => {
            item.HIDDEN         = false
            return item.SELECT
        })
        if (items.length < 1) {
            return REJECT('nothing selected')
        }
        if (items.length > 1) {
            return REJECT('please select only one item')
        }
        return RESOLVE(items[0])
    }
    //--------------------------------------------------------------------------
    getSelectedIdUpdP(opts:any={}) {
        return this.getSelectedItemP()
        .then((item:any) => RESOLVE({ id:item.id, update_date:item.update_date }) )
    }
    //--------------------------------------------------------------------------
    getSelectedIdP(opts:any={}) {
        return this.getSelectedItemP()
        .then((item:any) => RESOLVE(item.id) )
    }
    //--------------------------------------------------------------------------
    selectionChangedP() {
        return RESOLVE()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export class ListModal extends ListComp {
    //--------------------------------------------------------------------------
    comp_type                   = 'modal'
    //--------------------------------------------------------------------------
    onInitP() {
        this.defineList         (this.listDefn      )
        this.setHeader          ('=menu='           )
        this.onCriteria         (this.onCriteriaP   )
//         this.listClick          (this.onSelectItmP  )
        this.listClick          (this.onReturnItmP  )
        this.listDclick         (this.onReturnItmP  )

        return this.loadAndFocusP()
    }
    //--------------------------------------------------------------------------
    onReturnItmP(idx:number) {
        return this.modalRESOLVE(this.list[idx])
    }
    //--------------------------------------------------------------------------
    onCancelP() {
this.log('onCancelP')
        return this.modalREJECT()
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
export const List               =
{ Comp                          : ListComp
, Modal                         : ListModal
, Div                           : ListDiv
}
//------------------------------------------------------------------------------
//------------------------------------------------------------------------------
