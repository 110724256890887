//------------------------------------------------------------------------------
import  { Injectable                                } from '@angular/core'
//------------------------------------------------------------------------------
import * as lib from '../library'
//------------------------------------------------------------------------------
export const Imports            = lib.Imports
export const BaseModule         = lib.Base.Module

export const ItemComp           = lib.Item.Comp
export const ItemDiv            = lib.Item.Div
export const ItemModal          = lib.Item.Modal
export const ListComp           = lib.List.Comp
export const ListDiv            = lib.List.Div
export const SelectDiv          = lib.Select.Div
export const SelectModal        = lib.Select.Modal
export const TabbedComp         = lib.Base.Tabbed
//------------------------------------------------------------------------------
@Injectable()
export class PrdService extends lib.Base.Service {
    //--------------------------------------------------------------------------
    allTabs             :any    = ['dtl','lic','att','tag','txt','img','vrn','pri','grp','csl','chd','par']
    validTabs           :any    = this.allTabs
    //--------------------------------------------------------------------------
    showValidTabs() {
        this.allTabs.forEach((tab:string) => {
            this.stabs[tab]     = (this.validTabs.indexOf(tab) > -1)
        })
    }
    //--------------------------------------------------------------------------
    setHeader(values:any) {
        this.ctx.header         = !values?.id        ? 'new...'
                                : !values?.matchcode ? `${values.number}: ${values.name}`
                                                     : `${values.number}: ${values.matchcode}`
        this.ctx.number         = values.number
    }
    //--------------------------------------------------------------------------
}
//------------------------------------------------------------------------------
export const PrdTemplate        = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <span     *ngIf ='btns.tr.length && ctx.prd_id'
                            class='actions top floatr {{ctx.header && "header"}}'>
      <button *ngFor='let btn of btns.tr'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK("form", $event)'>{{btn.lab}}</button>
    </span>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details     </div>
    <div *ngIf='stabs.lic'  (click)='stabSel("lic")' class='{{stabCls("lic")}}'>licensing   </div>
    <div *ngIf='stabs.att'  (click)='stabSel("att")' class='{{stabCls("att")}}'>attributes  </div>
    <div *ngIf='stabs.tag'  (click)='stabSel("tag")' class='{{stabCls("tag")}}'>tags        </div>
    <div *ngIf='stabs.txt'  (click)='stabSel("txt")' class='{{stabCls("txt")}}'>text        </div>
    <div *ngIf='stabs.img'  (click)='stabSel("img")' class='{{stabCls("img")}}'>images      </div>
    <div *ngIf='stabs.vrn'  (click)='stabSel("vrn")' class='{{stabCls("vrn")}}'>variants    </div>
    <div *ngIf='stabs.pri'  (click)='stabSel("pri")' class='{{stabCls("pri")}}'>price list  </div>
    <div *ngIf='stabs.grp'  (click)='stabSel("grp")' class='{{stabCls("grp")}}'>groups      </div>
    <div *ngIf='stabs.csl'  (click)='stabSel("csl")' class='{{stabCls("csl")}}'>carousels   </div>
    <div *ngIf='stabs.par'  (click)='stabSel("par")' class='{{stabCls("par")}}'>parents     </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <PrdDtl    *ngSwitchDefault     ></PrdDtl   >
    <PrdLic    *ngSwitchCase='"lic"'></PrdLic   >
    <PrdAttLst *ngSwitchCase='"att"'></PrdAttLst>
    <PrdTagLst *ngSwitchCase='"tag"'></PrdTagLst>
    <PrdTxtLst *ngSwitchCase='"txt"'></PrdTxtLst>
    <PrdImgLst *ngSwitchCase='"img"'></PrdImgLst>
    <PrdVrnLst *ngSwitchCase='"vrn"'></PrdVrnLst>
    <PrdPriLst *ngSwitchCase='"pri"'></PrdPriLst>
    <PrdGrpLst *ngSwitchCase='"grp"'></PrdGrpLst>
    <PrdCslLst *ngSwitchCase='"csl"'></PrdCslLst>
    <PrdParLst *ngSwitchCase='"par"'></PrdParLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
export const PrdFamTemplate     = `
<div class='tabs container'>
  <div class='tabshead'>
    <h3 class='header floatl'>
      <button               class='btn back'
                            (click)='popClick()'
      >{{glob.backArrow}}</button>{{ctx.header}}</h3>
    <span     *ngIf ='btns.tr.length && ctx.prd_id'
                            class='actions top floatr {{ctx.header && "header"}}'>
      <button *ngFor='let btn of btns.tr'
                            class='{{btn.cls}} {{btn.offClass}} {{btn.hide}}'
                            (click)='btn.CLICK("form", $event)'>{{btn.lab}}</button>
    </span>
    <div class='u-cf'></div>
  </div>
  <div class='tabstabs'>
    <div                    (click)='stabSel("dtl")' class='{{stabCls("dtl")}}'>details     </div>
    <div *ngIf='stabs.lic'  (click)='stabSel("lic")' class='{{stabCls("lic")}}'>licensing   </div>
    <div *ngIf='stabs.att'  (click)='stabSel("att")' class='{{stabCls("att")}}'>attributes  </div>
    <div *ngIf='stabs.tag'  (click)='stabSel("tag")' class='{{stabCls("tag")}}'>tags        </div>
    <div *ngIf='stabs.txt'  (click)='stabSel("txt")' class='{{stabCls("txt")}}'>text        </div>
    <div *ngIf='stabs.img'  (click)='stabSel("img")' class='{{stabCls("img")}}'>images      </div>
    <div *ngIf='stabs.chd'  (click)='stabSel("chd")' class='{{stabCls("chd")}}'>children    </div>
  </div>
  <div class='tabsbody' [ngSwitch]='stab'>
    <PrdFamDtl *ngSwitchDefault     ></PrdFamDtl>
    <PrdLic    *ngSwitchCase='"lic"'></PrdLic   >
    <PrdAttLst *ngSwitchCase='"att"'></PrdAttLst>
    <PrdTagLst *ngSwitchCase='"tag"'></PrdTagLst>
    <PrdTxtLst *ngSwitchCase='"txt"'></PrdTxtLst>
    <PrdImgLst *ngSwitchCase='"img"'></PrdImgLst>
    <PrdChdLst *ngSwitchCase='"chd"'></PrdChdLst>
  </div>
</div>
`
//------------------------------------------------------------------------------
