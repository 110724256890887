//------------------------------------------------------------------------------
declare const RESOLVE, REJECT
//------------------------------------------------------------------------------
import  { Component                                 } from '@angular/core'
import  { NgModule                                  } from '@angular/core'
//------------------------------------------------------------------------------
import  { Base, Item, List, Select                  } from './library'
//------------------------------------------------------------------------------
const template                  = `
<div (keydown.escape)='glob.onEsc()'>
  <MenuTag/>
  <MainTag/>
  <dialog class='AlertTag'><AlertTag/></dialog>
  <dialog class='BusyTag' ><BusyTag /></dialog>
  <dialog class='ModalTag'><ModalTag/></dialog>
</div>
`
//  <editor [init]="{
//      height: 500,
//      menubar: false,
//      plugins: [
//        'advlist autolink lists link image charmap print preview anchor',
//        'searchreplace visualblocks code fullscreen',
//        'insertdatetime media table paste code help wordcount'
//      ],
//      toolbar:
//        'undo redo | formatselect | bold italic backcolor | \
//        alignleft aligncenter alignright alignjustify | \
//        bullist numlist outdent indent | removeformat | help code'
//    }"
//  ></editor>
//------------------------------------------------------------------------------
@Component({selector:'App', template:template})
export class App extends Base.Comp {
    //--------------------------------------------------------------------------
//     constructor() { super('main') }
    constructor() { super() }
    comp_type                   = 'main'
    //--------------------------------------------------------------------------
    onInitP() {
        this.glob.modalClasses  = [ Item.Modal, List.Modal, Select.Modal ]
        return this.glob.startP()
    }
    //--------------------------------------------------------------------------
}
//==============================================================================
import  { BrowserModule                             } from '@angular/platform-browser'
// import  { EditorModule                              } from '@tinymce/tinymce-angular'
import  { LibModule                                 } from './lib/module'

import  { DivModule                                 } from './div/module'

import  { AiqModule                                 } from './aiq/module'
import  { ArtModule                                 } from './art/module'
import  { AttModule                                 } from './att/module'
import  { BrnModule                                 } from './brn/module'
import  { CatModule                                 } from './cat/module'
import  { ChnModule                                 } from './chn/module'
import  { CodModule                                 } from './cod/module'
import  { CodLngModule                              } from './codLng/module'
import  { CpaModule                                 } from './cpa/module'
import  { CslModule                                 } from './csl/module'
import  { CurModule                                 } from './cur/module'
import  { DtyCodModule                              } from './dtyCod/module'
import  { EmlModule                                 } from './eml/module'
import  { EmpModule                                 } from './emp/module'
import  { EntModule                                 } from './ent/module'
import  { EvnQueModule                              } from './evnQue/module'
import  { GrpModule                                 } from './grp/module'
import  { IdlModule                                 } from './idl/module'
import  { KntModule                                 } from './knt/module'
import  { LndModule                                 } from './lnd/module'
import  { MkrModule                                 } from './mkr/module'
import  { NmbModule                                 } from './nmb/module'
import  { OlsModule                                 } from './ols/module'
import  { OrdModule                                 } from './ord/module'
import  { PayModule                                 } from './pay/module'
import  { PckModule                                 } from './pck/module'
import  { PrdModule                                 } from './prd/module'
import  { PriDefModule                              } from './priDef/module'
import  { PriPrdModule                              } from './priPrd/module'
import  { PriSkuModule                              } from './priSku/module'
import  { PrtLabModule                              } from './prtLab/module'
import  { PrtQueModule                              } from './prtQue/module'
import  { PtnModule                                 } from './ptn/module'
import  { RbgModule                                 } from './rbg/module'
import  { RgnModule                                 } from './rgn/module'
import  { RolModule                                 } from './rol/module'
import  { RptModule                                 } from './rpt/module'
import  { RsrModule                                 } from './rsr/module'
import  { RtnModule                                 } from './rtn/module'
import  { RulModule                                 } from './rul/module'
import  { ScpModule                                 } from './scp/module'
import  { ShlModule                                 } from './shl/module'
import  { ShpModule                                 } from './shp/module'
import  { StkModule                                 } from './stk/module'
import  { TacModule                                 } from './tac/module'
import  { TaxModule                                 } from './tax/module'
import  { TktModule                                 } from './tkt/module'
import  { TxtModule                                 } from './txt/module'
import  { UsrModule                                 } from './usr/module'
import  { UtlModule                                 } from './utl/module'
import  { VraModule                                 } from './vra/module'
import  { VrgModule                                 } from './vrg/module'
import  { WndModule                                 } from './wnd/module'
import  { WrgModule                                 } from './wrg/module'
import  { WrkModule                                 } from './wrk/module'
import  { WtlModule                                 } from './wtl/module'

import  { SelectArtModule                           } from './art/module'
import  { SelectCatModule                           } from './cat/module'
import  { SelectCslModule                           } from './csl/module'
import  { SelectEmpModule                           } from './emp/module'
import  { SelectEntModule                           } from './ent/module'
import  { SelectGrpModule                           } from './grp/module'
import  { SelectKntModule                           } from './knt/module'
import  { SelectKntAdrModule                        } from './knt/module'
import  { SelectPrdModule                           } from './prd/module'
import  { SelectRolModule                           } from './rol/module'
import  { SelectScpModule                           } from './scp/module'

import  { ParamsModule                              } from './lib/params'
//------------------------------------------------------------------------------
@NgModule(
    { bootstrap                 : [ App ]
    , declarations              : [ App ]
    , imports                   :
        [ BrowserModule
//         , EditorModule
        , LibModule

        , DivModule

        , AiqModule
        , ArtModule
        , AttModule
        , BrnModule
        , CatModule
        , ChnModule
        , CodModule
        , CodLngModule
        , CpaModule
        , CslModule
        , CurModule
        , DtyCodModule
        , EmlModule
        , EmpModule
        , EntModule
        , EvnQueModule
        , GrpModule
        , IdlModule
        , KntModule
        , LndModule
        , MkrModule
        , NmbModule
        , OlsModule
        , OrdModule
        , PayModule
        , PckModule
        , PrdModule
        , PriDefModule
        , PriPrdModule
        , PriSkuModule
        , PrtLabModule
        , PrtQueModule
        , PtnModule
        , RbgModule
        , RgnModule
        , RolModule
        , RptModule
        , RsrModule
        , RtnModule
        , RulModule
        , ScpModule
        , ShlModule
        , ShpModule
        , StkModule
        , TacModule
        , TaxModule
        , TktModule
        , TxtModule
        , UsrModule
        , UtlModule
        , VraModule
        , VrgModule
        , WndModule
        , WrgModule
        , WrkModule
        , WtlModule

        , SelectArtModule
        , SelectCatModule
        , SelectCslModule
        , SelectEmpModule
        , SelectEntModule
        , SelectGrpModule
        , SelectKntModule
        , SelectKntAdrModule
        , SelectPrdModule
        , SelectRolModule
        , SelectScpModule

        , ParamsModule
        ]
    }
)
export class AppModule extends Base.Module {}
//------------------------------------------------------------------------------
